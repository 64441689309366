(function ($) {
    'use strict';

    $(document).ready(function () {
        var $sticky = $('header');
        var $menuNav = $('.menu-nav');
        var scroll = $(window).scrollTop();
        var headerHeight = $sticky.height();

        /**
         * Initialise le statut de la navbar au chargement de la page
         */
        if (scroll > headerHeight) {
            $sticky.addClass('sticky');
            $menuNav.addClass('menu-sticky');
        }

        /**
         * Change le statut de la navbar au scroll
         */
        $(window).scroll(function () {
            scroll = $(window).scrollTop();
            if (scroll > headerHeight) {
                $sticky.addClass('sticky');
                $menuNav.addClass('menu-sticky');
            } else {
                $sticky.removeClass('sticky');
                $menuNav.removeClass('menu-sticky');
            }
        });

    });
}(jQuery.noConflict()));

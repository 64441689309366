(function($) {
    'use strict';

    $(document).ready(function() {
        var $searchForm = $("#search-form"),
            $bgHover = $(".menu-nav__background-hover");

        $(".open-searchbar").click(function(e) {
            e.preventDefault();
            if($searchForm.hasClass("search-form--opened")){
                $searchForm.removeClass("search-form--opened");
                $bgHover.removeClass("clicked");
                $(this).parents("li").removeClass("opened");
            } else {
                $searchForm.addClass("search-form--opened");
                $bgHover.addClass("clicked");
                $(this).parents("li").addClass("opened");
            }
        });

        $bgHover.click(function() {
            $(this).removeClass("clicked");
            $searchForm.removeClass("search-form--opened");
        });
    });

}(jQuery.noConflict()));

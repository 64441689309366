// Bandeau CNIL / généré sur https://cookieconsent.insites.com
window.addEventListener("load", function(){
    "use strict";

    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#E4E4E4",
                "text": "#323232"
            },
            "button": {
                "background": "#96c14c",
                "text": "#ffffff"
            }
        },
        "content": {
            "message": "En poursuivant votre navigation sur notre site, vous acceptez l'utilisation de cookies pour réaliser des statistiques de visites.",
            "dismiss": "J'accepte",
            "link": "En savoir plus",
            "href": "/Gestion-cookies"
        }
    });
});

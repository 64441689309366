(function ($) {
    'use strict';

    $(document).ready(function () {

        var bpScreenMedium = 982,
            windowWidth = $(window).width();

        /**
         * Carousel
         * @returns {void}
         */
        var createCarousel = function() {
            var $homeSlide = $("#owl-carousel"),
                $newSlide = $("#owl-carousel-news"),
                owlOptions = {
                    nav: true, // Show next and prev buttons
                    dots: false,
                    margin: 20,
                    items: 3
                };

            $homeSlide.owlCarousel({
                nav: false, // hide next and prev buttons
                items: 1,
                loop: true,
                autoplay: true,
                smartSpeed: 800,
                dotsContainer: '#home-carousel-dots'
            });

            if (windowWidth > bpScreenMedium) {
                $newSlide.owlCarousel(owlOptions);
            } else {
                $newSlide.addClass('off');
            }

            $(window).resize(function () {
                windowWidth = $(window).width();
                if (windowWidth > bpScreenMedium) {
                    if ($('#owl-carousel-news').hasClass('off')) {
                        $newSlide.owlCarousel(owlOptions);
                        $newSlide.removeClass('off');
                    }
                } else {
                    if (!$('#owl-carousel-news').hasClass('off')) {
                        $newSlide.addClass('off').trigger('destroy.owl.carousel');
                        $newSlide.find('.owl-stage-outer').children(':eq(0)').unwrap();
                    }
                }
            });
        };

        /**
         * Counter
         * @returns {void}
         */
        var drawCirclePercent = function() {

            $(".references__section__figure").counterUp({
                delay: 10, // the delay time in ms
                time: 1500 // the speed time in ms
            });


            $('.percentage').each(function() {
                var percentageValue = $(this).prev(".percentage-value").text(),
                    colorProgress = '',
                    limitHight = 75,
                    limitMedium = 50,
                    limitSmall = 25;

                if (percentageValue >= limitHight) {
                    colorProgress = 'rgba(149, 192, 75, 1)';
                } else if (percentageValue >= limitMedium && percentageValue < limitHight) {
                    colorProgress = 'rgba(253, 238, 33, 1)';
                } else if (percentageValue >= limitSmall && percentageValue < limitMedium) {
                    colorProgress = 'rgba(255, 159, 63, 1)';
                } else {
                    colorProgress = 'rgba(249, 82, 43, 1)';
                }

                $(this).ClassyLoader({
                    width: 80, // width of the loader in pixels
                    height: 80, // height of the loader in pixels
                    animate: true, // whether to animate the loader or not
                    displayOnLoad: true,
                    percentage: percentageValue, // percent of the value, between 0 and 100
                    speed: 1, // miliseconds between animation cycles, lower value is faster
                    roundedLine: false, // whether the line is rounded, in pixels
                    showRemaining: false, // how the remaining percentage (100% - percentage)
                    fontFamily: 'Helvetica', // name of the font for the percentage
                    fontSize: '18px', // size of the percentage font, in pixels
                    showText: true, // whether to display the percentage text
                    diameter: 30, // diameter of the circle, in pixels
                    fontColor: 'rgba(0, 0, 0, 1)', // color of the font in the center of the loader, any CSS color would work, hex, rgb, rgba, hsl, hsla
                    lineColor: colorProgress, // line color of the main circle
                    lineWidth: 5 // the width of the circle line in pixels
                });
            });
        };

        /**
         * Scroll to top
         * @returns {void}
         */
        var scrollTopAction = function() {
            $('#scroll-to-top').click(function () {
                $("html, body").animate({scrollTop: 0}, "slow");
            });
        };

        /**
         * Table sorter
         * @returns {void}
         */
        var tableSort = function() {
            $('.table-sort').DataTable({
                info: false,
                paging: false,
                searching: false,
                columnDefs: [
                  { type: 'date-uk', targets: 'date-sort' }
                ]
            });
        };

        /**
         * Toggle sidebar mobile
         * @returns {void}
         */
        var openSidebar = function() {
            $('.btn-side-bar').click(function () {
                $(this).toggleClass('open');
                $('.side-bar').toggleClass('opened');
            });
        };

        /**
         * Survol des documents associés
         * @returns {void}
         */
        var hoverDocument = function() {
            var docUrl = '';

            $('.has-file').click(function () {
                docUrl = $(this).find('a').attr('href');
                if (docUrl) {
                    window.open(docUrl,'_blank');
                    // Envoi de l'action pour analytics
                    // ga('send', 'event', 'Téléchargement', 'clic', docUrl);
                    // console.log('send', 'event', 'Téléchargement', 'clic', docUrl);
                }
            });
        };

        /**
         * INIT
         */
        createCarousel();
        drawCirclePercent();
        tableSort();
        scrollTopAction();
        openSidebar();
        hoverDocument();
    });

    $( window ).load(function() {
        $("html").addClass("loaded");
    });

    /**
     * Gestion de l'accordéon pour les documents associées.
     */
    $(document).ready(function () {
        $('#next-docs a.last-docs--attached__more__link').click(function (event) {
            event.stopPropagation();
            var classes = $('#next-docs').attr('data-visible');
            if (classes == 1) {
                $('#next-docs a > div').text('Voir tous les documents ');
                $('#next-docs').attr('data-visible', 0);
                $('.last-docs--attached__next').addClass('hidden');
            }
            else {
                $('#next-docs a > div').text('Masquer tous les documents ');
                $('#next-docs').attr('data-visible', 1);
                $('.last-docs--attached__next').removeClass('hidden');
            }
            return false;
        });
    });

    /**
     * Gestion de l'accordéon pour les pages associées.
     */
    $(document).ready(function () {
        $('#next-pages a.last-pages--attached__more__link').click(function (event) {
            event.stopPropagation();
            var classes = $('#next-pages').attr('data-visible');
            if (classes == 1) {
                $('#next-pages a > div').text('Voir toutes les pages associées');
                $('#next-pages').attr('data-visible', 0);
                $('.last-pages--attached__next').addClass('hidden');
            }
            else {
                $('#next-pages  a > div').text('Masquer toutes les pages associées ');
                $('#next-pages ').attr('data-visible', 1);
                $('.last-pages--attached__next').removeClass('hidden');
            }
            return false;
        });
    });

    /**
     * Gestion de l'accordéon pour les liens associées.
     */
    $(document).ready(function () {
        $('#next-links a.last-links--attached__more__link').click(function (event) {
            event.stopPropagation();
            var classes = $('#next-links').attr('data-visible');
            if (classes == 1) {
                $('#next-links a > div').text('Voir toutes les liens associées');
                $('#next-links').attr('data-visible', 0);
                $('.last-links--attached__next').addClass('hidden');
            }
            else {
                $('#next-links  a > div').text('Masquer toutes les liens associées ');
                $('#next-links ').attr('data-visible', 1);
                $('.last-links--attached__next').removeClass('hidden');
            }
            return false;
        });
    });

}(jQuery.noConflict()));

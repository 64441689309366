(function ($) {
    'use strict';

    $(document).ready(function () {

        var device = '';
        var mobileCondition = false;

        /**
         * identification de l'écran
         * @returns {void}
         */
        var getDevice = function () {
            var winSize = $('#site_screen_type').css('font-family');

            winSize = winSize.replace(/"/g, '');
            device = winSize;
            mobileCondition = (device === 'screen-minimum' || device === 'screen-small' || device === 'screen-medium');
        };

        /**
         * Navigation Desktop
         * @returns {void}
         */
        var hoverMenuDesktop = function () {
            $(".menu-nav > ul > li").hover(function() {
                if(!$(this).is(".search-wrapper")){
                    $(".menu-nav__background-hover").addClass("hover");
                }
            },
                function() {
                    $(".menu-nav__background-hover").removeClass("hover");
                }
            );
        };

        /**
         * Navigation Desktop
         * @returns {void}
         */
        var sousMenuFiliere = function () {
            $(".menu-filiere__submenu > li").each(function (index, elem) {
                if ($(elem).find("ul").length !== 0) {
                    $(elem).addClass("has-children");
                } else {
                    $(elem).removeClass("has-children");
                }
            });
        };

        /**
         * Ouvrir la navigation mobile (click sur bouton burger)
         * @returns {void}
         */
        var openMobileMenu = function () {
            $(".navbar-toggle").click(function() {
                if (mobileCondition) {
                    $(this).toggleClass('pressed');
                    $('.menu-nav__list-item').toggleClass('open');
                }
            });
        };

        /**
         *  Reset class du menu mobile si changement d'écran
         *  @returns {void}
         */
        var resetMobileMenu = function () {
            if (!mobileCondition) {
                $(".navbar-toggle").removeClass('pressed');
                $(".menu-nav__list-item").removeClass('open');
                $(".menu-nav__submenu-container").removeClass('opened-mobile');
            }
        };

        /**
         * Sous-menu Mobile
         * @returns {void}
         */
        var openMobileSubmenu = function() {
            $(".menu-nav__link_dropdown").click(function(e) {
                if (mobileCondition) {
                    e.preventDefault();
                    if($(this).hasClass("expanded-mobile")) {
                        $(this).removeClass("expanded-mobile");
                        $(this).next(".menu-nav__submenu-container").removeClass("opened-mobile");
                    } else {
                        $(".menu-nav__link_dropdown").removeClass("expanded-mobile");
                        $(".menu-nav__submenu-container").removeClass("opened-mobile");
                        $(this).addClass("expanded-mobile");
                        $(this).next(".menu-nav__submenu-container").addClass("opened-mobile");
                    }
                }
            });
        };

        getDevice();
        hoverMenuDesktop();
        sousMenuFiliere();
        openMobileMenu();
        openMobileSubmenu();

        $(window).resize(function() {
            getDevice();
            resetMobileMenu();
        });

    });
}(jQuery.noConflict()));

// Input date polyfill
webshim.setOptions("forms-ext", {
    replaceUI: 'true', // Met le datepicker partout ('auto' met en place le polyfill que si nécessaire)
    types: 'date',
    "widgets": {
        "startView": 2, // La pop-up s'ouvre sur le choix du jour
        "minView": 1, // On peu remonter a minima sur la grille du mois
        "openOnFocus": true
    }
});
webshims.polyfill('forms forms-ext');
